.room-imgs {
  width: 65%;
}
.room-details .room-details-main {
  display: flex;
  gap: 30px;
  width: 95%;
  /* border: 1px solid; */
  margin: auto;
}
.room-details .room-details-main .desc {
  width: 30%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  padding: 0 20px;
  height: auto;
  background-color: #f3f3f5;
}
.room-features {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: auto;
  background-color: rgba(0, 0, 0, 0.884);
  color: white;
}
.room-features .each {
  text-align: center;
  padding: 20px 40px;
}
.room-features .each h3 {
  color: #b48c58;
}

.room-features .each:nth-child(even) {
  border-bottom: 1px solid #444444;
  border-left: 1px solid #444444;
}
.room-features .each:nth-child(odd) {
  border-right: 1px solid #444444;
  border-bottom: 1px solid #444444;
}
@media only screen and (max-width: 767px) {
  .room-details-main {
    flex-direction: column;
    width: 100%;
  }
  .room-details .room-details-main .desc {
    width: 100%;
    padding: 0;
  }
  .room-imgs {
    width: 100%;
  }
}
