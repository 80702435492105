.stay-hero {
  width: 100%;
}
.stay-hero img {
  width: 100%;
}
.stay-desc {
  width: 100%;
  height: 60vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.stay-desc .stay-div-img {
  border: 10px solid;
  border-top: none;
  border-left: none;
  padding: 0 15px 10px 0;
}
.stay-desc .stay-div-img img {
  width: 100%;
  /* height: 100%; */
}
.stay-desc .desc {
  background-color: #310606;
  color: white;
  padding-left: 15px;
}
.stay-desc .desc h1 {
  border-bottom: 2px solid white;
}

.dining-desc {
  width: 85%;
  margin: auto;
  padding: 0 15px;
  line-height: 20px;
}
@media screen and (max-width: 480px) {
  .stay-desc {
    grid-template-columns: repeat(1, 1fr);
  }
  .stay-desc .stay-div-img {
    border: none;
    padding: none;
  }
  .blank {
    margin-bottom: 100px;
  }
}
