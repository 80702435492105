.wedding-desc-heading {
  padding: 0 35px 0 35px;
}
.wedding-desc-div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 95%;
  margin: auto;
}

.wedding-desc-div .wedding-desc {
  background-color: #651f1b;
  padding: 30px;
  color: white;
}
.wedding-desc-div .img-div img {
  width: 100%;
}
.wedding-desc-div .img-div {
  padding: 0 0 10px 20px;
  border-bottom: 5px solid #651f1b;
}
@media screen and (max-width: 480px) {
  .wedding-desc-heading {
    padding: 0;
    text-align: center;
  }
  .wedding-desc-div {
    display: flex;
    flex-direction: column;
  }
  .wedding-desc-div .img-div {
    order: -1;
    padding: 0;
  }
}
