.hero-section {
  width: 100%;

  position: relative;
}

.each-slide {
  width: 100%;
  position: relative;
}
.each-slide img {
  width: 100%;
}
.overlay-text h1 {
  font-size: 60px;
}
.overlay-text {
  position: absolute;
  top: 50%;
  left: 70%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.616);
  padding: 10px;
  border-radius: 5px;
  z-index: 1; /* Ensure the overlay text appears above the images */
}

.overlay-text p {
  margin: 0;
  font-size: 20px;
  color: black;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .overlay-text {
    display: none;
  }
}
