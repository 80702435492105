.dining-hero {
  width: 100%;
}
.dining-hero img {
  width: 100%;
}
.dining-desc {
  width: 95%;
  margin: auto;
  padding: 0 15px;
  /* line-height: 20px; */
}

.dining-type .type {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 95%;
  margin: auto;
  margin-top: 40px;
}
.dining-type .type .img-div img {
  width: 100%;
}
.dining-type .type .type-desc {
  padding: 0 20px;
  line-height: 20px;
}
.dining-type .type:nth-of-type(1) .type-desc {
  background-color: #7e654f;
  color: white;
}
.dining-type .type:nth-of-type(2) .type-desc {
  background-color: #dfe8e7;
}
.dining-type .type:nth-of-type(3) .type-desc {
  background-color: #d4935c;
}
.dining-type .type:nth-of-type(4) .type-desc {
  background-color: #7e654f;
  color: white;
}

.dining-type .type:nth-of-type(1) .img-div {
  padding: 0 20px 10px 0;
  border-bottom: 5px solid #7e654f;
}
.dining-type .type:nth-of-type(2) .img-div {
  padding: 0 0 10px 20px;
  border-bottom: 5px solid #dfe8e7;
}
.dining-type .type:nth-of-type(3) .img-div {
  padding: 0 20px 10px 0;
  border-bottom: 5px solid #d4935c;
}
.dining-type .type:nth-of-type(4) .img-div {
  padding: 0 0 10px 20px;
  border-bottom: 5px solid #7e654f;
}
@media screen and (max-width: 480px) {
  .dining-desc h1 {
    line-height: 40px;
  }

  .dining-type .type {
    display: flex;
    flex-direction: column;
  }
  .dining-type .type .img-div {
    padding: 0;
    border: none;
    margin: 0;
  }

  .type:nth-child(even) .img-div {
    order: -1;
  }
}
