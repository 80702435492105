.main_gallery {
  text-align: center;
}

.gallery {
  width: 97%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 10px;
  margin: auto;
}

.gallery img {
  width: 100%;
  max-width: 100%;
  cursor: pointer;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.842);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.modal {
  background-color: #ffffff00;
  border-radius: 5px;
}

.modal img {
  max-width: 100%;
  max-height: 80vh;
}

.modal button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.modal button:hover {
  background-color: #555;
}
