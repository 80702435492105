.restaurant-main {
  /* Add styles as needed */
}
.restaurant-main h1,
p {
  text-align: center;
}

.restaurants {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: auto;
}
.restaurants img {
  width: 100%;
}
.restaurant-item {
  position: relative;
  display: inline-block;
  margin: 10px;
}

.image-name {
  margin-top: 5px;
}

.description-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent 310606 background */
  color: white;
  padding: 10px;
  border-radius: 5px;
  text-decoration: none;
  opacity: 0; /* Initially hidden */
  transition: opacity 1s ease, transform 5s ease; /* Smooth transition for sliding effect */
  transform: translateY(100%); /* Initially off-screen */
}

.restaurant-item:hover .description-overlay {
  opacity: 1; /* Show the description */
  transform: translateY(0); /* Slide upward into view */
}
@media only screen and (max-width: 767px) {
  .restaurants {
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
  }
}
