.navbar {
  background-color: #310606;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 40px;
  z-index: 20;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  max-width: 1500px;
  position: relative;
}
.nav-logo {
  border-radius: 50px;
  width: 9%;
  background-color: #310606;
  margin-left: 35px;
}

.nav-logo img {
  width: 100%;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
}

.nav-links {
  color: #f2e2b0;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
}

.nav-item {
  line-height: 40px;
  margin-right: 1rem;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  /* background: #b48c58; */
}

.nav-item .active {
  color: #b48c58;
  border-bottom: 3px solid #b48c58;
  font-weight: bold;
}

.nav-icon {
  display: none;
  color: #f5b921;
}

.nav-icon .icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
}

.book-btn {
  border: 1px solid #b48c58;
  color: white;
  text-decoration: none;
  background-color: #b48c58;
  padding: 5px 15px;
}

@media screen and (max-width: 960px) {
  .navbar {
    top: 0;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 89%;
    border-top: 1pxsolid #fff;
    position: absolute;
    top: 80px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }
  .nav-item {
    line-height: 30px;
    /* border-bottom: 1px solid black; */
    width: 90%;
  }
  .nav-item a {
    display: block;
    margin-left: -2.5rem;
  }

  .nav-menu.active {
    background: #2c0101;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    align-items: center;
  }
  .nav-item .active {
    color: #b48c58;
    border: none;
  }
  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }
  .nav-logo {
    border-radius: 50px;
    width: 25%;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-100%, -50%);
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    color: #b48c58;
  }
}
