.abt-sect {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-color: #651f1b;
}

.abt-sect .home-about {
  color: white;
  padding: 0 25px;
}
.abt-sect .home-about p {
  text-align: left;
}
.abt-sect .home-about a {
  color: white;
  border: 1px solid white;
  text-decoration: none;
  padding: 5px 15px;
  border-radius: 20px;
}

.home-img img {
  width: 100%;
  height: 100%;
}

/* Media Query for Tablets */
@media screen and (max-width: 768px) {
  .abt-sect {
    grid-template-columns: 1fr; /* Change grid layout to single column for tablets */
  }

  .abt-sect .home-about {
    padding: 0 15px; /* Reduce padding on tablet screens */
  }
}

@media screen and (max-width: 480px) {
  .abt-sect {
    display: flex;
    flex-direction: column;
  }
  .abt-sect .home-img {
    order: -1;
  }

  .abt-sect .home-about {
    padding: 0 10px;
    text-align: center;
  }

  .abt-sect .home-about a {
    display: block;
    margin: 10px auto;
  }
}
