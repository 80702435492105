.facilities_main {
  width: 100%;
  background-color: #651f1b;
  color: white;
  padding: 40px 0;
  /* text-align: center; */
  margin-top: 40px;
}
.facilities_main h1 {
  text-align: center;
}
.facilities_main .facilities {
  width: 55%;
  display: flex;
  justify-content: space-between;
  margin: auto;
  text-align: center;
  /* border: 1px solid; */
  /* background-color: rgba(0, 0, 0, 0.842); */
  padding: 10px 20px;
  margin-top: 30px;
}
.facilities_main > p {
  width: 90%;
  margin: auto;
}
.features {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  /* border: 1px solid; */
  /* background-color: rgba(0, 0, 0, 0.842); */
}
.feat-div {
  display: flex;
  line-height: 35px;
}

.features > div ul li {
  list-style: none;
  /* color: white; */
}
@media only screen and (max-width: 767px) {
  .facilities_main .facilities {
    flex-direction: column;
  }
  .features {
    flex-direction: column;
  }
}
